import { tailwindMerge } from '@air/tailwind-variants';
import { ComboboxOption, ComboboxOptionProps } from '@reach/combobox';

import { SEARCH_SUGGESTION } from '~/constants/testIDs';
import { preventDefaultMouseEvent } from '~/utils/ClickUtils';

interface SearchSuggestionContainerProps extends ComboboxOptionProps {
  value: string;
  index: number;
  className?: string;
  onClick: () => void;
}

export const SearchSuggestionContainer = ({ value, className, ...props }: SearchSuggestionContainerProps) => {
  return (
    <ComboboxOption
      data-testid={`${SEARCH_SUGGESTION}-${value}`}
      value={value}
      className={tailwindMerge(
        'search-suggestion-container mb-1 flex items-center rounded p-1.5 hover:bg-grey-4',
        className,
      )}
      onMouseDown={preventDefaultMouseEvent}
      {...props}
    />
  );
};

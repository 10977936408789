import { tailwindMerge } from '@air/tailwind-variants';
import { type ComponentPropsWithoutRef } from 'react';

export type CatInBoxAssetProps = Pick<ComponentPropsWithoutRef<'svg'>, 'className'>;

export const CatInBoxAsset = ({ className }: CatInBoxAssetProps) => {
  return (
    <svg
      className={tailwindMerge('h-[138px] w-[201px]', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="138"
      fill="none"
    >
      <g clip-path="url(#a)">
        <path
          fill="var(--colors-grey8)"
          d="M39.01 123.733c3.985 0 7.215-.641 7.215-1.432 0-.791-3.23-1.433-7.214-1.433-3.985 0-7.215.642-7.215 1.433s3.23 1.432 7.215 1.432ZM24.233 122.301c1.634 0 2.96-.321 2.96-.717 0-.395-1.326-.716-2.96-.716s-2.96.321-2.96.716c0 .396 1.326.717 2.96.717Z"
        />
        <path
          fill="var(--colors-grey5)"
          d="M191.544 112.176a4.644 4.644 0 0 0 4.643-4.645 4.645 4.645 0 0 0-4.643-4.646 4.644 4.644 0 0 0-4.642 4.646 4.644 4.644 0 0 0 4.642 4.645Z"
        />
        <path
          stroke="var(--colors-grey8)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2.5"
          d="M170.5 93h29M1.95 93h28M62 34.5 30.5 47.155v62.293l70 27.052 70-27.052V47.155L139 34.5"
        />
        <path fill="var(--colors-grey8)" d="m61.97 35.191-16.828 7.395 13.482 16.609L61.97 35.19Z" />
        <path
          stroke="var(--colors-grey8)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2.5"
          d="M142.976 58.13c.503-27.003-21.045-56.737-21.045-56.737l-6.325 15.66-30.019.175L74.833 1.374C59.958 24.061 58.5 57.999 58.5 57.999"
        />
        <path
          stroke="var(--colors-grey8)"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="2.5"
          d="M30.5 47.678v62.292l70 26.578 70-26.578V47.678l-70 26.578-70-26.578ZM100.5 136.547V74.255"
        />
        <path
          fill="var(--colors-grey8)"
          d="M100.558 34.689a1.354 1.354 0 1 0 0-2.71 1.354 1.354 0 0 0 0 2.71ZM116.69 34.689a1.355 1.355 0 1 0-.002-2.71 1.355 1.355 0 0 0 .002 2.71Z"
        />
        <path
          fill="var(--colors-grey5)"
          d="M42.744 123.54a7.044 7.044 0 0 0 7.04-7.047 7.044 7.044 0 0 0-7.04-7.046 7.044 7.044 0 0 0-7.04 7.046 7.044 7.044 0 0 0 7.04 7.047Z"
        />
        <path
          fill="var(--colors-grey6)"
          d="M26.264 122.281a2.999 2.999 0 0 0 2.998-3 3 3 0 0 0-2.998-3.001 3 3 0 0 0 0 6.001Z"
        />
        <path
          stroke="var(--colors-grey6)"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="6"
          d="M164.601 101.666c1.392 12.95-7.969 24.565-20.909 25.958"
        />
      </g>
    </svg>
  );
};

import { SortDirection } from '@air/api/types';
import { memo } from 'react';

import { SortMenu } from '~/components/SortButtons/SortMenu';
import { SORT_BUTTON } from '~/constants/testIDs';

export interface ViewSortingMenuComponentProps {
  availableSortOptions: readonly string[];
  currentlySortedBy: string;
  sortDirection: SortDirection;
  dataTestId?: string;
  onChange: (sortingFieldName: string, direction: SortDirection) => void;
}

export const ViewSortingMenuComponent = memo(
  ({ currentlySortedBy, onChange, sortDirection, availableSortOptions }: ViewSortingMenuComponentProps) => {
    return (
      <SortMenu
        data-testid={SORT_BUTTON}
        currentSortBy={currentlySortedBy}
        sortOptions={availableSortOptions}
        onSortChange={onChange}
        direction={sortDirection}
      />
    );
  },
);

ViewSortingMenuComponent.displayName = 'ViewSortingMenuComponent';

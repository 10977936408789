import { BoardAncestors } from '@air/api/types';
import { Breadcrumbs, BreadcrumbsItem } from '@air/component-breadcrumbs';
import React, { memo, useMemo } from 'react';

interface BoardSuggestionBreadcrumbsProps {
  ancestors: BoardAncestors;
}

export const BoardSuggestionBreadcrumbs = memo(({ ancestors }: BoardSuggestionBreadcrumbsProps) => {
  const ancestorsItems = useMemo<BreadcrumbsItem[]>(() => {
    const items: BreadcrumbsItem[] = [];

    items.push(
      ...ancestors.map<BreadcrumbsItem>((ancestor) => ({
        className: 'text-inherit truncate font-normal px-0',
        id: ancestor.id,
        label: ancestor.title,
        type: 'item',
      })),
    );

    return items;
  }, [ancestors]);

  return <Breadcrumbs isCompact className="overflow-visible text-12 text-grey-10" items={ancestorsItems} />;
});

BoardSuggestionBreadcrumbs.displayName = 'BoardSuggestionBreadcrumbs';

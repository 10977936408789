import { tailwindVariants } from '@air/tailwind-variants';
import { ComponentProps, ReactNode } from 'react';

export const breadcrumbsButton = tailwindVariants({
  base: 'flex items-center rounded font-medium text-grey-9 hover:bg-grey-4 hover:text-grey-11 active:bg-grey-4 active:text-grey-12',
  variants: {
    size: {
      small: 'h-6 gap-1 px-1',
      medium: 'h-8 gap-2 px-3',
      large: 'h-10 gap-2 px-4',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type BreadcrumbsButtonProps = Omit<ComponentProps<'button'>, 'prefix'> & {
  onClick: () => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export const BreadcrumbsButton = ({ children, className, prefix, suffix, ...restOfProps }: BreadcrumbsButtonProps) => {
  return (
    <button className={breadcrumbsButton({ class: className })} {...restOfProps}>
      {!!prefix && <div className="shrink-0">{prefix}</div>}

      <div className="truncate">{children}</div>

      {!!suffix && <div className="shrink-0">{suffix}</div>}
    </button>
  );
};

import { tailwindMerge } from '@air/tailwind-variants';
import type { ComponentProps, ReactNode } from 'react';

export type BreadcrumbsItemProps = Omit<ComponentProps<'div'>, 'prefix'> & {
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export const BreadcrumbsItem = ({ children, className, prefix, suffix, ...restOfProps }: BreadcrumbsItemProps) => {
  return (
    <div className={tailwindMerge('flex items-center gap-1 px-1 font-medium text-grey-9', className)} {...restOfProps}>
      {!!prefix && <div className="shrink-0">{prefix}</div>}

      <div className="truncate">{children}</div>

      {!!suffix && <div className="shrink-0">{suffix}</div>}
    </div>
  );
};

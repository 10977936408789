import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps } from 'react';

export type BreadcrumbsSeparatorProps = ComponentProps<'div'>;

export const BreadcrumbsSeparator = ({ className, ...restOfProps }: BreadcrumbsSeparatorProps) => {
  return (
    <div className={tailwindMerge('text-12 font-medium text-inherit', className)} {...restOfProps}>
      /
    </div>
  );
};

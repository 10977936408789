import { CustomField } from '@air/api/types';
import { isNil } from 'lodash';

import { FilterType } from '~/components/FiltersMenu/types';
import { FilterParamNames } from '~/constants/search';
import { OtherFiltersType } from '~/store/filters/types';
import { FilterParamsType } from '~/utils/filters/filtersToUrlParamsUtils';

export const getExcludedFiltersFromImmutableFilters = (fastFilters: FilterParamsType) => {
  const excludedFilterTypes: Set<FilterType> = new Set();
  const excludedOtherFilters: Set<OtherFiltersType> = new Set();
  const excludedCustomFields: Set<CustomField['id']> = new Set();

  if (!!fastFilters.tagIdAnd?.length || !!fastFilters.tagIdOr?.length || !!fastFilters.tagIdNot?.length) {
    excludedFilterTypes.add('tags');
  }
  if (!!fastFilters.type?.length) excludedFilterTypes.add('type');
  if (!!fastFilters.uploaderId?.length) excludedFilterTypes.add('uploader');
  if (!!fastFilters.source?.length) excludedFilterTypes.add('source');
  if (!!fastFilters.ext?.length) excludedFilterTypes.add('extension');
  if (!!fastFilters.color?.length) excludedFilterTypes.add('color');
  if (!!fastFilters.uploadedDateEnd || !!fastFilters.uploadedDateStart) excludedFilterTypes.add('dateUploaded');
  if (!!fastFilters.recordedDateEnd || !!fastFilters.recordedDateStart) excludedFilterTypes.add('dateCreated');
  if (!!fastFilters.modifiedDateEnd || !!fastFilters.modifiedDateStart) excludedFilterTypes.add('dateModified');
  if (!!fastFilters[FilterParamNames.takenDateEnd] || !!fastFilters[FilterParamNames.takenDateStart])
    excludedFilterTypes.add('dateTaken');

  if (
    !!fastFilters[FilterParamNames.importedKeywordAnd]?.length ||
    !!fastFilters[FilterParamNames.importedKeywordOr]?.length ||
    !!fastFilters[FilterParamNames.importedKeywordNot]?.length
  ) {
    excludedFilterTypes.add('importedKeywords');
  }
  if (!!fastFilters[FilterParamNames.copyright]) excludedFilterTypes.add('copyright');

  if (!!fastFilters[FilterParamNames.creator]) excludedFilterTypes.add('creator');

  if (!!fastFilters[FilterParamNames.country]) excludedFilterTypes.add('country');
  if (!!fastFilters[FilterParamNames.city]) excludedFilterTypes.add('city');
  if (!!fastFilters[FilterParamNames.state]) excludedFilterTypes.add('state');

  if (!isNil(fastFilters.bookmarked)) excludedOtherFilters.add('is-favorited');
  if (!isNil(fastFilters.hasMultipleVersions)) excludedOtherFilters.add('has-versions');
  if (!isNil(fastFilters.hasOpenComments)) excludedOtherFilters.add('has-open-comments');
  if (!isNil(fastFilters.untagged)) excludedOtherFilters.add('is-untagged');
  if (!isNil(fastFilters.isOnBoards)) excludedOtherFilters.add('is-on-boards');

  if (fastFilters.customFields) {
    Object.keys(fastFilters.customFields).forEach((cfId) => excludedCustomFields.add(cfId));
  }

  return {
    excludedFilterTypes,
    excludedOtherFilters,
    excludedCustomFields,
  };
};

import { memo } from 'react';

/**
 * this div creates a black transparent overlay on top of the image
 * whent the card is selected
 */
export const SelectedGalleryCardOverlay = memo(() => (
  <div className="pointer-events-none absolute inset-0 rounded bg-black/40" />
));

SelectedGalleryCardOverlay.displayName = 'SelectedGalleryCardOverlay';

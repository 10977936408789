import React from 'react';

/**
 * This callback filter out events that are not from the left mouse button or are default prevented
 */
export const leftMouseEvent = (fn: (event: React.MouseEvent) => void) => (event: React.MouseEvent) => {
  if (event.button === 0 && !event.isDefaultPrevented()) {
    fn(event);
  }
};

export const preventDefaultMouseEvent = (event: React.MouseEvent) => event.preventDefault();

import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, memo } from 'react';

type NoSearchResultsInfoProps = ComponentProps<'p'>;

export const NoSearchResultsInfo = memo(({ className, ...props }: NoSearchResultsInfoProps) => (
  <p className={tailwindMerge('text-14 text-grey-11', className)} {...props} />
));

NoSearchResultsInfo.displayName = 'NoSearchResultsInfo';

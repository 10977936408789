import { FilterTrackingType } from '@air/analytics';
import { CustomField, Tag } from '@air/api/types';
import { endOfDay, formatISO, startOfDay, sub } from 'date-fns';
import { isNil, omitBy } from 'lodash';

import { ItemTypeFilter } from '~/components/Filters/types';
import { FilterParamNames, FilterParamNamesArray } from '~/constants/search';
import { DateFilter, Filters, SelectedFilter } from '~/store/filters/types';
import { getQueryWithoutParams } from '~/utils/PathUtils';

export const createTagsQuery = (
  tagsFilter?: Filters['tags'],
):
  | { [FilterParamNames.tagIdAnd]: Tag['label'][] }
  | { [FilterParamNames.tagIdOr]: Tag['label'][] }
  | { [FilterParamNames.tagIdNot]: Tag['label'][] }
  | null => {
  if (tagsFilter && tagsFilter.tags?.length) {
    const tagLabels = tagsFilter.tags.map(({ label }) => label);
    switch (tagsFilter.logic) {
      case 'and':
        return { [FilterParamNames.tagIdAnd]: tagLabels };
      case 'or':
        return { [FilterParamNames.tagIdOr]: tagLabels };
      case 'not':
        return { [FilterParamNames.tagIdNot]: tagLabels };
    }
  }
  return null;
};

export const createPeopleQuery = (
  peopleFilter?: Filters['people'],
): { [FilterParamNames.personIdAnd]: string[] } | { [FilterParamNames.personIdOr]: string[] } | null => {
  if (peopleFilter && peopleFilter.people?.length) {
    const peopleIds = peopleFilter.people.map(({ id }) => id);
    switch (peopleFilter.logic) {
      case 'and':
        return { [FilterParamNames.personIdAnd]: peopleIds };
      case 'or':
        return { [FilterParamNames.personIdOr]: peopleIds };
    }
  }
  return null;
};

export const createImportedKeywordsQuery = (
  keywordsFilter?: Filters['importedKeywords'],
):
  | { [FilterParamNames.importedKeywordAnd]: string[] }
  | { [FilterParamNames.importedKeywordOr]: string[] }
  | { [FilterParamNames.importedKeywordNot]: string[] }
  | null => {
  if (keywordsFilter && keywordsFilter.keywords?.length) {
    const keywords = keywordsFilter.keywords;
    switch (keywordsFilter.logic) {
      case 'and':
        return { [FilterParamNames.importedKeywordAnd]: keywords };
      case 'or':
        return { [FilterParamNames.importedKeywordOr]: keywords };
      case 'not':
        return { [FilterParamNames.importedKeywordNot]: keywords };
    }
  }
  return null;
};

export const createUploadersQuery = (
  uploadersFilter?: Filters['uploaders'],
): { [FilterParamNames.uploaderId]: string[] } | null =>
  !!uploadersFilter?.length
    ? { [FilterParamNames.uploaderId]: uploadersFilter.filter((f) => f).map((f) => f.value) }
    : null;

export const createExtensionsQuery = (
  extensionsFilter?: Filters['extensions'],
): { [FilterParamNames.ext]: string[] } | null =>
  !!extensionsFilter?.length ? { [FilterParamNames.ext]: extensionsFilter } : null;

export const createCamerasQuery = (
  cameraFilter?: Filters['camera'],
): {
  [FilterParamNames.cameraMake]?: string;
  [FilterParamNames.cameraModel]?: string[];
} | null => {
  if (!cameraFilter?.make && !cameraFilter?.models?.length) return null;

  const query: {
    [FilterParamNames.cameraMake]?: string;
    [FilterParamNames.cameraModel]?: string[];
  } = {};

  if (cameraFilter.make) {
    query[FilterParamNames.cameraMake] = cameraFilter.make;
  }

  if (cameraFilter.models?.length) {
    query[FilterParamNames.cameraModel] = cameraFilter.models;
  }
  return query;
};

export const createColorsQuery = (
  colorsFilters?: Filters['colors'],
): { [FilterParamNames.color]: Filters['colors'] } | null =>
  !!colorsFilters?.length ? { [FilterParamNames.color]: colorsFilters } : null;

export const createSourcesQuery = (
  sourcesFilter?: Filters['sources'],
): { [FilterParamNames.source]: string[] } | null =>
  !!sourcesFilter?.length ? { [FilterParamNames.source]: sourcesFilter } : null;

export const createVideoFrameRatesQuery = (
  videoFrameRatesFilter?: Filters['videoFrameRates'],
): { [FilterParamNames.videoFrameRate]: string[] } | null =>
  !!videoFrameRatesFilter?.length ? { [FilterParamNames.videoFrameRate]: videoFrameRatesFilter } : null;

export const createVideoAspectRatiosQuery = (
  videoAspectRatiosFilter?: Filters['videoAspectRatios'],
): { [FilterParamNames.videoAspectRatio]: string[] } | null =>
  !!videoAspectRatiosFilter?.length ? { [FilterParamNames.videoAspectRatio]: videoAspectRatiosFilter } : null;

export const createAudioCodingsQuery = (
  audioCodingsFilter?: Filters['audioCodings'],
): { [FilterParamNames.audioCoding]: string[] } | null =>
  !!audioCodingsFilter?.length ? { [FilterParamNames.audioCoding]: audioCodingsFilter } : null;

export const createAudioSampleRatesQuery = (
  audioSampleRatesFilter?: Filters['audioSampleRates'],
): { [FilterParamNames.audioSampleRate]: string[] } | null =>
  !!audioSampleRatesFilter?.length ? { [FilterParamNames.audioSampleRate]: audioSampleRatesFilter } : null;

export const createCustomFieldsQuery = (
  customFieldsFilter: Filters['customFields'] = [],
): { [key: string]: CustomField['id'][] } | null => {
  const fieldsWithValues = customFieldsFilter.filter(({ values }) => !!values.length);
  if (!fieldsWithValues.length) return null;
  return fieldsWithValues.reduce((acc, customField) => {
    let paramName = '';
    switch (customField.logic) {
      case 'and':
        paramName = `${FilterParamNames.cfIdAnd}${customField.id}`;
        break;
      case 'or':
        paramName = `${FilterParamNames.cfIdOr}${customField.id}`;
        break;
      case 'not':
        paramName = `${FilterParamNames.cfIdNot}${customField.id}`;
        break;
    }

    return { ...acc, [paramName]: customField.values.map(({ id }) => id) };
  }, {});
};

export const formatISODate = (date?: Date) => (date ? formatISO(date, { representation: 'complete' }) : undefined);

const getDateRange = (dateFilter?: DateFilter): { startDate?: string; endDate?: string } => {
  let startDate: Date | undefined;
  let endDate: Date | undefined;

  switch (dateFilter?.type) {
    case 'all-time':
      break;
    case 'today':
      startDate = new Date();
      endDate = new Date();
      break;
    case 'yesterday':
      startDate = sub(new Date(), { days: 1 });
      endDate = startDate;
      break;
    case 'last-week':
      startDate = sub(new Date(), { days: 7 });
      break;
    case 'last-month':
      startDate = sub(new Date(), { days: 30 });
      break;
    case 'last-year':
      startDate = sub(new Date(), { days: 365 });
      break;
    case 'custom-range': {
      startDate = dateFilter.startDate ? new Date(dateFilter.startDate) : undefined;
      endDate = dateFilter.endDate ? new Date(dateFilter.endDate) : undefined;
      break;
    }
  }

  return {
    startDate: startDate ? formatISODate(startOfDay(startDate)) : undefined,
    endDate: endDate ? formatISODate(endOfDay(endDate)) : undefined,
  };
};

export const createCreatedDateQuery = (
  dateFilter?: DateFilter,
): { [FilterParamNames.recordedDateStart]?: string; [FilterParamNames.recordedDateEnd]?: string } | null => {
  const range = getDateRange(dateFilter);
  return {
    [FilterParamNames.recordedDateStart]: range.startDate,
    [FilterParamNames.recordedDateEnd]: range.endDate,
  };
};

export const createModifiedDateQuery = (
  dateFilter?: DateFilter,
): { [FilterParamNames.modifiedDateStart]?: string; [FilterParamNames.modifiedDateEnd]?: string } | null => {
  const range = getDateRange(dateFilter);
  return {
    [FilterParamNames.modifiedDateStart]: range.startDate,
    [FilterParamNames.modifiedDateEnd]: range.endDate,
  };
};

export const createUploadedDateQuery = (
  dateFilter?: DateFilter,
): { [FilterParamNames.uploadedDateStart]?: string; [FilterParamNames.uploadedDateEnd]?: string } | null => {
  const range = getDateRange(dateFilter);
  return {
    [FilterParamNames.uploadedDateStart]: range.startDate,
    [FilterParamNames.uploadedDateEnd]: range.endDate,
  };
};

export const createDateTakenQuery = (
  dateFilter?: DateFilter,
): { [FilterParamNames.takenDateStart]?: string; [FilterParamNames.takenDateEnd]?: string } | null => {
  const range = getDateRange(dateFilter);
  return {
    [FilterParamNames.takenDateStart]: range.startDate,
    [FilterParamNames.takenDateEnd]: range.endDate,
  };
};

export const createTypesQuery = (typeFilters?: Filters['types']) => {
  if (!typeFilters?.length) return undefined;
  return { [FilterParamNames.type]: typeFilters };
};

export const createCopyrightQuery = (
  copyrightFilter?: Filters['copyrights'],
): { [FilterParamNames.copyright]: string[] } | null =>
  !!copyrightFilter?.length ? { [FilterParamNames.copyright]: copyrightFilter } : null;

export const createCountriesQuery = (
  countryFilter?: Filters['countries'],
): { [FilterParamNames.country]: string[] } | null =>
  !!countryFilter?.length ? { [FilterParamNames.country]: countryFilter } : null;

export const createCitiesQuery = (cityFilter?: Filters['cities']): { [FilterParamNames.city]: string[] } | null =>
  !!cityFilter?.length ? { [FilterParamNames.city]: cityFilter } : null;

export const createStatesQuery = (stateFilter?: Filters['states']): { [FilterParamNames.state]: string[] } | null =>
  !!stateFilter?.length ? { [FilterParamNames.state]: stateFilter } : null;

export const createOtherFiltersQuery = (
  otherFilters?: Filters['others'],
):
  | {
      [FilterParamNames.bookmarked]?: boolean;
      [FilterParamNames.isOnBoards]?: boolean;
      [FilterParamNames.hasOpenComments]?: boolean;
      [FilterParamNames.hasMultipleVersions]?: boolean;
      [FilterParamNames.untagged]?: boolean;
    }
  | undefined => {
  if (!otherFilters?.length) return undefined;
  let filters = {};
  if (otherFilters?.includes('is-favorited')) {
    filters = { ...filters, [FilterParamNames.bookmarked]: true };
  }

  if (otherFilters?.includes('has-open-comments')) {
    filters = { ...filters, [FilterParamNames.hasOpenComments]: true };
  }

  if (otherFilters?.includes('has-versions')) {
    filters = { ...filters, [FilterParamNames.hasMultipleVersions]: true };
  }

  if (otherFilters?.includes('is-on-boards')) {
    filters = { ...filters, [FilterParamNames.isOnBoards]: true };
  }

  if (otherFilters?.includes('is-untagged')) {
    filters = { ...filters, [FilterParamNames.untagged]: true };
  }

  return filters;
};

export const createCreatorQuery = (
  creatorFilter?: Filters['creators'],
): { [FilterParamNames.creator]: string[] } | null =>
  !!creatorFilter?.length ? { [FilterParamNames.creator]: creatorFilter } : null;

export const getUrlParamsWithoutFilters = (query: string) => {
  const newUrlParams = getQueryWithoutParams(query, FilterParamNamesArray);

  const urlParamsKeys = Array.from(newUrlParams.keys());

  for (const name of urlParamsKeys) {
    if (
      name.startsWith(FilterParamNames.cfIdAnd) ||
      name.startsWith(FilterParamNames.cfIdOr) ||
      name.startsWith(FilterParamNames.cfIdNot)
    ) {
      newUrlParams.delete(name);
    }
  }
  return newUrlParams;
};

export interface FilterParamsType {
  [FilterParamNames.tagIdOr]?: string[];
  [FilterParamNames.tagIdAnd]?: string[];
  [FilterParamNames.tagIdNot]?: string[];
  [FilterParamNames.source]?: string[];
  [FilterParamNames.type]?: ItemTypeFilter[];
  [FilterParamNames.color]?: string[];
  [FilterParamNames.uploaderId]?: string[];
  [FilterParamNames.ext]?: string[];
  [FilterParamNames.bookmarked]?: boolean;
  [FilterParamNames.isOnBoards]?: boolean;
  [FilterParamNames.hasMultipleVersions]?: boolean;
  [FilterParamNames.hasOpenComments]?: boolean;
  [FilterParamNames.untagged]?: boolean;
  [FilterParamNames.recordedDateStart]?: string;
  [FilterParamNames.recordedDateEnd]?: string;
  [FilterParamNames.uploadedDateStart]?: string;
  [FilterParamNames.uploadedDateEnd]?: string;
  [FilterParamNames.modifiedDateStart]?: string;
  [FilterParamNames.modifiedDateEnd]?: string;
  [FilterParamNames.takenDateStart]?: string;
  [FilterParamNames.takenDateEnd]?: string;
  [FilterParamNames.libraryId]?: string;
  [FilterParamNames.boardId]?: string;
  [FilterParamNames.onlyInLibraries]?: boolean;
  [FilterParamNames.importedKeywordAnd]?: string[];
  [FilterParamNames.importedKeywordOr]?: string[];
  [FilterParamNames.importedKeywordNot]?: string[];
  [FilterParamNames.copyright]?: string[];
  [FilterParamNames.creator]?: string[];
  [FilterParamNames.cameraModel]?: string[];
  [FilterParamNames.cameraMake]?: string;
  [FilterParamNames.videoFrameRate]?: string[];
  [FilterParamNames.country]?: string[];
  [FilterParamNames.city]?: string[];
  [FilterParamNames.state]?: string[];
  [FilterParamNames.videoAspectRatio]?: string[];
  [FilterParamNames.audioCoding]?: string[];
  [FilterParamNames.audioSampleRate]?: string[];
  [FilterParamNames.personIdAnd]?: string[];
  [FilterParamNames.personIdOr]?: string[];
  customFields?: { [key: string]: string[] };
}

export const getFilterParamsNames = (filter: SelectedFilter) => {
  switch (filter.type) {
    case 'date':
    case 'location':
      return [];
    case 'type':
      return [FilterParamNames.type];
    case 'uploader':
      return [FilterParamNames.uploaderId];
    case 'color':
      return [FilterParamNames.color];
    case 'customField': {
      return [
        `${FilterParamNames.cfIdAnd}${filter.customField?.id}`,
        `${FilterParamNames.cfIdNot}${filter.customField?.id}`,
        `${FilterParamNames.cfIdOr}${filter.customField?.id}`,
      ];
    }
    case 'copyright':
      return [FilterParamNames.copyright];
    case 'creator':
      return [FilterParamNames.creator];
    case 'tags':
      return [FilterParamNames.tagIdAnd, FilterParamNames.tagIdOr, FilterParamNames.tagIdNot];
    case 'extension':
      return [FilterParamNames.ext];
    case 'source':
      return [FilterParamNames.source];
    case 'other':
      return [
        FilterParamNames.bookmarked,
        FilterParamNames.hasMultipleVersions,
        FilterParamNames.hasOpenComments,
        FilterParamNames.isOnBoards,
        FilterParamNames.untagged,
      ];
    case 'dateCreated':
      return [FilterParamNames.recordedDateStart, FilterParamNames.recordedDateEnd];
    case 'dateUploaded':
      return [FilterParamNames.uploadedDateEnd, FilterParamNames.uploadedDateStart];
    case 'dateModified':
      return [FilterParamNames.modifiedDateStart, FilterParamNames.modifiedDateEnd];
    case 'country':
      return [FilterParamNames.country];
    case 'city':
      return [FilterParamNames.city];
    case 'state':
      return [FilterParamNames.state];
    case 'dateTaken':
      return [FilterParamNames.takenDateStart, FilterParamNames.takenDateEnd];
    case 'camera':
      return [FilterParamNames.cameraModel, FilterParamNames.cameraMake];
    case 'videoFrameRate':
      return [FilterParamNames.videoFrameRate];
    case 'videoAspectRatio':
      return [FilterParamNames.videoAspectRatio];
    case 'audioCoding':
      return [FilterParamNames.audioCoding];
    case 'audioSampleRate':
      return [FilterParamNames.audioSampleRate];
    case 'people':
      return [FilterParamNames.personIdAnd, FilterParamNames.personIdOr];
    default:
      return [];
  }
};

export const getUrlParamsFromFilters = (filters: Partial<Filters>) => {
  const appliedFilters = {
    ...createTypesQuery(filters.types),
    ...createTagsQuery(filters.tags),
    ...createPeopleQuery(filters.people),
    ...createUploadersQuery(filters.uploaders),
    ...createExtensionsQuery(filters.extensions),
    ...createColorsQuery(filters.colors),
    ...createSourcesQuery(filters.sources),
    ...createCustomFieldsQuery(filters.customFields),
    ...createCreatedDateQuery(filters.createdDate),
    ...createUploadedDateQuery(filters.uploadedDate),
    ...createModifiedDateQuery(filters.modifiedDate),
    ...createDateTakenQuery(filters.takenDate),
    ...createCreatorQuery(filters.creators),
    ...createOtherFiltersQuery(filters.others),
    ...createImportedKeywordsQuery(filters.importedKeywords),
    ...createCopyrightQuery(filters.copyrights),
    ...createCamerasQuery(filters.camera),
    ...createVideoFrameRatesQuery(filters.videoFrameRates),
    ...createCountriesQuery(filters.countries),
    ...createCitiesQuery(filters.cities),
    ...createStatesQuery(filters.states),
    ...createVideoAspectRatiosQuery(filters.videoAspectRatios),
    ...createAudioCodingsQuery(filters.audioCodings),
    ...createAudioSampleRatesQuery(filters.audioSampleRates),
  };

  return omitBy(appliedFilters, isNil);
};

export const filterParamsToTrackingType = (filters: ReturnType<typeof getUrlParamsFromFilters>) => {
  const appliedFilters: FilterTrackingType[] = [];
  const cfFiltersKeys = Object.keys(filters).filter(
    (key) =>
      key.startsWith(FilterParamNames.cfIdAnd) ||
      key.startsWith(FilterParamNames.cfIdOr) ||
      key.startsWith(FilterParamNames.cfIdNot),
  );
  if (cfFiltersKeys.length) appliedFilters.push('custom-fields');
  if (filters.tagIdAnd || filters.tagIdOr || filters.tagIdNot) appliedFilters.push('tags');
  if (filters.modifiedDateEnd || filters.modifiedDateStart) appliedFilters.push('date-modified');
  if (filters.recordedDateEnd || filters.recordedDateStart) appliedFilters.push('date-created');
  if (filters.uploadedDateEnd || filters.uploadedDateStart) appliedFilters.push('date-uploaded');
  if (filters.dateTakenEnd || filters.dateTakenStart) appliedFilters.push('date-taken');

  if (filters[FilterParamNames.videoFrameRate]) appliedFilters.push('video-frame-rate');
  if (filters[FilterParamNames.videoAspectRatio]) appliedFilters.push('video-aspect-ratios');
  if (filters[FilterParamNames.audioCoding]) appliedFilters.push('audio-coding');
  if (filters[FilterParamNames.audioSampleRate]) appliedFilters.push('audio-sample-rate');

  if (filters.type) appliedFilters.push('type');
  if (filters.source) appliedFilters.push('source');
  if (filters.color) appliedFilters.push('color');
  if (filters.bookmarked) appliedFilters.push('favorited');
  if (filters.hasOpenComments) appliedFilters.push('has-open-comments');
  if (filters.hasMultipleVersions) appliedFilters.push('has-versions');
  if (filters.isOnBoards) appliedFilters.push('is-on-boards');
  if (filters.untagged) appliedFilters.push('is-untagged');
  if (filters.uploaderId) appliedFilters.push('uploader');
  if (filters.ext) appliedFilters.push('extension');
  if (
    filters[FilterParamNames.importedKeywordAnd] ||
    filters[FilterParamNames.importedKeywordOr] ||
    filters[FilterParamNames.importedKeywordNot]
  )
    appliedFilters.push('imported-keywords');
  if (filters[FilterParamNames.copyright]) appliedFilters.push('copyright');
  if (filters[FilterParamNames.creator]) appliedFilters.push('creator');
  if (filters[FilterParamNames.cameraModel] || filters[FilterParamNames.cameraMake]) appliedFilters.push('camera');
  if (filters[FilterParamNames.country]) appliedFilters.push('country');
  if (filters[FilterParamNames.state]) appliedFilters.push('state');
  if (filters[FilterParamNames.city]) appliedFilters.push('city');

  if (filters[FilterParamNames.personIdAnd] || filters[FilterParamNames.personIdOr]) appliedFilters.push('people');
  return appliedFilters;
};

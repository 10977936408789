import { EntityLookupOptions } from '@air/api';
import { useMemo } from 'react';

import { useFiltersContext } from '~/providers/FiltersProvider';
import { customFieldIdFromUrlParam } from '~/utils/filters/urlParamsToFiltersUtils';

export const useAppliedCustomFieldsFilters = () => {
  const {
    filters: { customFields },
  } = useFiltersContext();

  const appliedCustomFieldsFilters = useMemo(() => {
    if (!customFields) return [];
    return Object.keys(customFields).reduce<EntityLookupOptions['customFields']>((collected, paramName) => {
      const cfId = customFieldIdFromUrlParam(paramName);
      if (!cfId) return;

      const valuesIds = customFields[paramName];
      collected!.push({
        id: cfId,
        customFieldValueIds: valuesIds,
      });
      return collected;
    }, []);
  }, [customFields]);

  return {
    appliedCustomFieldsFilters,
  };
};

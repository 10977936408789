import { ComboboxList, ComboboxPopover } from '@reach/combobox';
import { rgba } from 'polished';
import { memo, PropsWithChildren } from 'react';

import { SEARCH_SUGGESTIONS_CONTAINER } from '~/constants/testIDs';

type SearchSuggestionsProps = object;

export const SearchSuggestions = memo(({ children }: PropsWithChildren<SearchSuggestionsProps>) => {
  return (
    <div>
      {/* This box prevents a gap between searchinput and suggestions box shadow - suggestions are displayed on portal */}
      <div className="h-[5px]" style={{ width: `calc(100% + 12x)`, boxShadow: `0px 0px 8px ${rgba(0, 0, 0, 0.2)}` }} />
      <ComboboxPopover
        data-testid={SEARCH_SUGGESTIONS_CONTAINER}
        className="max-h-[calc(100vh-70px)] overflow-auto rounded-b-lg border-0 bg-grey-1 shadow-popover md:border-t md:border-t-grey-5"
        style={{
          boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <ComboboxList className="p-2">{children}</ComboboxList>
      </ComboboxPopover>
    </div>
  );
});

SearchSuggestions.displayName = 'SearchSuggestions';

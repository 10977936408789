import Link, { LinkProps } from 'next/link';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

import { breadcrumbsButton } from './BreadcrumbsButton';

export type BreadcrumbsLinkProps = Omit<ComponentPropsWithoutRef<'a'>, keyof LinkProps | 'prefix'> &
  LinkProps & {
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

export const BreadcrumbsLink = ({
  children,
  className,
  href,
  prefix,
  suffix,
  ...restOfProps
}: BreadcrumbsLinkProps) => {
  return (
    <Link className={breadcrumbsButton({ class: className })} href={href} {...restOfProps}>
      {!!prefix && <div className="shrink-0">{prefix}</div>}
      <div className="truncate">{children}</div>
      {!!suffix && <div className="shrink-0">{suffix}</div>}
    </Link>
  );
};

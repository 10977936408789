import { useMemo } from 'react';

import { useFiltersContext } from '~/providers/FiltersProvider';

export const useAppliedTagsFilters = () => {
  const {
    filters: { tagIdOr = [], tagIdNot = [], tagIdAnd = [] },
  } = useFiltersContext();

  const appliedTagsFilters = useMemo(() => [...tagIdOr, ...tagIdNot, ...tagIdAnd], [tagIdAnd, tagIdNot, tagIdOr]);

  return { appliedTagsFilters };
};

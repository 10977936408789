import { EntityLookupResponse } from '@air/api';
import { FilterLookupsResponse } from '@air/api/types';
import { cloneDeep, keyBy } from 'lodash';
import { useCallback } from 'react';
import isEqual from 'react-fast-compare';
import { batch, useDispatch } from 'react-redux';

import { useApplyFilters } from '~/hooks/filters/useApplyFilters';
import { useFilterParams } from '~/hooks/filters/useFilterParams';
import { useFiltersContext } from '~/providers/FiltersProvider';
import { setFiltersValuesAction, setSelectedFiltersAction } from '~/store/filters/actions';
import { filtersValuesSelector } from '~/store/filters/selectors';
import { CustomFieldSelectedFilter, Filters, initialFiltersState, SelectedFilter } from '~/store/filters/types';
import { getExcludedFiltersFromImmutableFilters } from '~/utils/filters/fastFiltersUtils';
import { getUrlParamsFromFilters } from '~/utils/filters/filtersToUrlParamsUtils';
import {
  getColorsStateFromParams,
  getCustomFieldsStateFromParams,
  getDateStateFromParams,
  getImportedKeywordsStateFromParams,
  getLocationStateFromParams,
  getOthersStateFromParams,
  getPeopleStateFromParams,
  getTagsStateFromParams,
  getTypesStateFromParams,
  getUploadersStateFromParams,
} from '~/utils/filters/urlParamsToFiltersUtils';
import { isDefined } from '~/utils/isDefined';
import { useAirStore } from '~/utils/ReduxUtils';

export const useSetFiltersFromContextToRedux = ({
  entities,
  options,
}: {
  options?: FilterLookupsResponse;
  entities?: EntityLookupResponse;
}) => {
  const dispatch = useDispatch();
  const filters = useFilterParams();

  const store = useAirStore();

  const { applyFilters } = useApplyFilters();

  const { filters: filtersParams, immutableFilters = {} } = useFiltersContext();

  const setFiltersFromContextToRedux = useCallback(() => {
    if (!entities || !options) {
      return;
    }

    const { uploaders: uploadersOptions = [] } = options;
    const { customFields: customFieldsOptions = [], tags: tagsOptions = [], people: peopleOptions = [] } = entities;

    const currentFilters = filtersValuesSelector(store.getState());
    const currentUrlParams = getUrlParamsFromFilters(currentFilters);

    const { customFields: urlCustomFields, ...restUrlParams } = filtersParams;
    const filtersUrlParams = {
      ...restUrlParams,
      ...urlCustomFields,
    };

    if (isEqual(filtersUrlParams, currentUrlParams)) {
      return;
    }

    const { excludedOtherFilters, excludedFilterTypes, excludedCustomFields } =
      getExcludedFiltersFromImmutableFilters(immutableFilters);

    const { selectedFilters, ...filtersState } = cloneDeep(initialFiltersState);
    const newSelectedFilters: SelectedFilter[] = selectedFilters;
    const newFiltersState: Filters = filtersState;

    const tags = getTagsStateFromParams(filters.tagsParam, tagsOptions);
    const tagsAreMissing = tags.tags.some((tag) => !tag);
    if (tagsAreMissing) {
      tags.tags = tags.tags.filter((tag) => !!tag);
    }
    if (!excludedFilterTypes.has('tags') && tags?.tags?.length) {
      newSelectedFilters.push({ type: 'tags' });
      newFiltersState.tags = tags;
    }

    const people = getPeopleStateFromParams(filters.peopleParam, peopleOptions);
    const peopleAreMissing = people.people.some((person) => !person);
    if (peopleAreMissing) {
      people.people = people.people.filter((person) => !!person);
    }
    if (!excludedFilterTypes.has('people') && people?.people?.length) {
      newSelectedFilters.push({ type: 'people' });
      newFiltersState.people = people;
    }

    let uploaders = getUploadersStateFromParams(filters.uploadersParam, uploadersOptions);
    const uploadersAreMissing = uploaders.some((uploader) => !uploader);
    if (uploadersAreMissing) {
      uploaders = uploaders.filter((uploader) => !!uploader);
    }
    if (!excludedFilterTypes.has('uploader') && uploaders?.length) {
      newSelectedFilters.push({ type: 'uploader' });
      newFiltersState.uploaders = uploaders;
    }

    if (!excludedFilterTypes.has('color')) {
      const colors = getColorsStateFromParams(filters.colorsParam);
      if (colors?.length) {
        newSelectedFilters.push({ type: 'color' });
        newFiltersState.colors = colors;
      }
    }

    const others = getOthersStateFromParams({
      bookmarked: excludedOtherFilters.has('is-favorited') ? undefined : filters.bookmarkedParam,
      hasVersions: excludedOtherFilters.has('has-versions') ? undefined : filters.hasVersionsParam,
      hasComments: excludedOtherFilters.has('has-open-comments') ? undefined : filters.hasOpenCommentsParam,
      untagged: excludedOtherFilters.has('is-untagged') ? undefined : filters.untaggedParam,
      isOnBoards: excludedOtherFilters.has('is-on-boards') ? undefined : filters.isOnBoardsParam,
    });
    if (others?.length) {
      newSelectedFilters.push({ type: 'other' });
      newFiltersState.others = others;
    }

    const types = getTypesStateFromParams(filters.typesParam);
    if (types?.length) {
      newSelectedFilters.push({ type: 'type' });
      newFiltersState.types = types;
    }

    const sources = filters.sourcesParam?.or;
    if (!excludedFilterTypes.has('source') && sources?.length) {
      newSelectedFilters.push({ type: 'source' });
      newFiltersState.sources = sources;
    }

    const copyrights = filters.copyrightsParam?.or || [];
    if (!excludedFilterTypes.has('copyright') && copyrights?.length) {
      newSelectedFilters.push({ type: 'copyright' });
      newFiltersState.copyrights = copyrights;
    }

    const { states, cities, countries } = getLocationStateFromParams(filters.locationParam);

    if (!excludedFilterTypes.has('country') && countries?.length) {
      newSelectedFilters.push({ type: 'country' });
      newFiltersState.countries = countries;
    }

    if (!excludedFilterTypes.has('state') && states?.length) {
      newSelectedFilters.push({ type: 'state' });
      newFiltersState.states = states;
    }

    if (!excludedFilterTypes.has('city') && cities?.length) {
      newSelectedFilters.push({ type: 'city' });
      newFiltersState.cities = cities;
    }

    const extensions = filters.extensionsParam?.or || [];
    if (!excludedFilterTypes.has('extension') && extensions?.length) {
      newSelectedFilters.push({ type: 'extension' });
      newFiltersState.extensions = extensions;
    }

    const importedKeywords = getImportedKeywordsStateFromParams(filters.importedKeywordsParam);
    if (!excludedFilterTypes.has('importedKeywords') && importedKeywords?.keywords?.length) {
      newSelectedFilters.push({ type: 'importedKeywords' });
      newFiltersState.importedKeywords = importedKeywords;
    }

    const cameras = filters.camerasParam?.or ?? [];
    if (!excludedFilterTypes.has('camera') && cameras?.length) {
      newSelectedFilters.push({ type: 'camera' });

      newFiltersState.camera = {
        make: cameras[0]?.make ?? '',
        models: cameras.map(({ model }) => model).filter(isDefined),
      };
    }

    const videoAspectRatios = filters.videoAspectRatiosParam?.or;
    if (!excludedFilterTypes.has('videoAspectRatio') && videoAspectRatios?.length) {
      newSelectedFilters.push({ type: 'videoAspectRatio' });
      newFiltersState.videoAspectRatios = videoAspectRatios;
    }

    const audioCodings = filters.audioCodingsParam?.or;
    if (!excludedFilterTypes.has('audioCoding') && audioCodings?.length) {
      newSelectedFilters.push({ type: 'audioCoding' });
      newFiltersState.audioCodings = audioCodings;
    }

    const audioSampleRates = filters.audioSampleRatesParam?.or;
    if (!excludedFilterTypes.has('audioSampleRate') && audioSampleRates?.length) {
      newSelectedFilters.push({ type: 'audioSampleRate' });
      newFiltersState.audioSampleRates = audioSampleRates.map((rate) => rate.toString());
    }

    const createdDate = getDateStateFromParams(filters.createdDateParam);
    if (!excludedFilterTypes.has('dateCreated') && createdDate) {
      newSelectedFilters.push({ type: 'dateCreated' });
      newFiltersState.createdDate = createdDate;
    }

    const videoFrameRates = filters.videoFrameRatesParam?.or;
    if (!excludedFilterTypes.has('videoFrameRate') && videoFrameRates?.length) {
      newSelectedFilters.push({ type: 'videoFrameRate' });
      newFiltersState.videoFrameRates = videoFrameRates.map((v) => v.toString());
    }

    const uploadedDate = getDateStateFromParams(filters.uploadedDateParam);
    if (!excludedFilterTypes.has('dateUploaded') && uploadedDate) {
      newSelectedFilters.push({ type: 'dateUploaded' });
      newFiltersState.uploadedDate = uploadedDate;
    }

    const modifiedDate = getDateStateFromParams(filters.updatedDateParam);
    if (!excludedFilterTypes.has('dateModified') && modifiedDate) {
      newSelectedFilters.push({ type: 'dateModified' });
      newFiltersState.modifiedDate = modifiedDate;
    }

    const takenDate = getDateStateFromParams(filters.takenDateParam);
    if (!excludedFilterTypes.has('dateTaken') && takenDate) {
      newSelectedFilters.push({ type: 'dateTaken' });
      newFiltersState.takenDate = takenDate;
    }

    const customFieldsById = keyBy(customFieldsOptions, 'id');
    let customFields = getCustomFieldsStateFromParams(filters.customFieldsParam, customFieldsById);

    const valuesAreMissing = customFields.some((cf) => cf.values.some((value) => !value));
    if (valuesAreMissing) {
      customFields = customFields.reduce<CustomFieldSelectedFilter[]>((acc, cf) => {
        const values = cf.values.filter((value) => !!value);
        if (values.length && !excludedCustomFields.has(cf.id)) {
          acc.push({
            ...cf,
            values,
          });
        }
        return acc;
      }, []);
    }

    const creators = filters.creatorsParam?.or || [];
    if (!excludedFilterTypes.has('creator') && creators?.length) {
      newSelectedFilters.push({ type: 'creator' });
      newFiltersState.creators = creators;
    }

    let customFieldsState: SelectedFilter[] = customFields?.map((customField) => ({
      type: 'customField',
      customField: customFieldsById[customField.id]
        ? {
            name: customFieldsById[customField.id].name,
            id: customField.id,
            type: customFieldsById[customField.id].type,
          }
        : undefined,
    }));

    const customFieldsAreMissing = customFieldsState.some((cf) => !cf.customField);
    if (customFieldsAreMissing) {
      customFields = customFields.reduce((acc, curr) => {
        if (customFieldsById[curr.id]) {
          acc.push(curr);
        }
        return acc;
      }, [] as CustomFieldSelectedFilter[]);
      customFieldsState = customFieldsState.filter((cf) => !!cf.customField);
    }

    if (customFields?.length) {
      newSelectedFilters.push(...customFieldsState);
      newFiltersState.customFields = customFields;
    }

    // if any filter value is missing (e.g. tag has been deleted or user is on a different workspace
    // remove outdated filters from url
    if (tagsAreMissing || customFieldsAreMissing || valuesAreMissing || uploadersAreMissing) {
      applyFilters({ filters: newFiltersState });
    }
    batch(() => {
      dispatch(setFiltersValuesAction({ filters: newFiltersState }));
      dispatch(setSelectedFiltersAction({ filters: newSelectedFilters }));
    });
  }, [
    entities,
    options,
    store,
    filtersParams,
    immutableFilters,
    filters.tagsParam,
    filters.peopleParam,
    filters.uploadersParam,
    filters.bookmarkedParam,
    filters.hasVersionsParam,
    filters.hasOpenCommentsParam,
    filters.untaggedParam,
    filters.isOnBoardsParam,
    filters.typesParam,
    filters.sourcesParam?.or,
    filters.copyrightsParam?.or,
    filters.locationParam,
    filters.extensionsParam?.or,
    filters.importedKeywordsParam,
    filters.camerasParam?.or,
    filters.videoAspectRatiosParam?.or,
    filters.audioCodingsParam?.or,
    filters.audioSampleRatesParam?.or,
    filters.createdDateParam,
    filters.videoFrameRatesParam?.or,
    filters.uploadedDateParam,
    filters.updatedDateParam,
    filters.takenDateParam,
    filters.customFieldsParam,
    filters.creatorsParam?.or,
    filters.colorsParam,
    applyFilters,
    dispatch,
  ]);

  return {
    setFiltersFromContextToRedux,
  };
};

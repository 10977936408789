import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, forwardRef } from 'react';

import { SEARCH_STATIC_HEADER_Z_INDEX } from '~/constants/WorkspaceSpacing';

export type TopBarProps = Pick<ComponentProps<'div'>, 'children' | 'className' | 'style'>;

export const TopBar = forwardRef<HTMLDivElement, TopBarProps>(({ children, className, style }, forwardedRef) => {
  return (
    <div
      className={tailwindMerge(
        'sticky inset-x-0 top-0 flex shrink-0 items-start justify-between bg-grey-3 px-3 py-1 text-12 font-medium text-grey-11 md:bg-grey-1 md:px-12 md:py-4',
        className,
      )}
      style={{
        zIndex: SEARCH_STATIC_HEADER_Z_INDEX,
        ...style,
      }}
      ref={forwardedRef}
    >
      {children}
    </div>
  );
});

TopBar.displayName = 'TopBar';

import { isArray } from 'lodash';
import Router from 'next/router';
import { useCallback } from 'react';

import { QueryParamNames } from '~/constants/search';
import { Filters, SelectedFilter } from '~/store/filters/types';
import {
  getFilterParamsNames,
  getUrlParamsFromFilters,
  getUrlParamsWithoutFilters,
} from '~/utils/filters/filtersToUrlParamsUtils';
import { getQueryWithoutParams, pushWithNewParams } from '~/utils/PathUtils';

const pushWithFilters = ({
  filters,
  pathname,
  urlParams,
}: {
  pathname: string;
  urlParams: URLSearchParams;
  filters: { [key: string]: any };
}) => {
  Object.keys(filters).forEach((filterKey) => {
    const value = filters[filterKey];
    if (isArray(value)) {
      value.forEach((filterValue) => urlParams.append(filterKey, filterValue));
    } else {
      urlParams.append(filterKey, filters[filterKey]);
    }
  });

  const queryString = urlParams.toString();
  const urlToGo = queryString ? `${pathname}?${queryString}` : pathname;

  return Router.push(urlToGo, undefined, { shallow: true });
};

/**
 * This hook takes selected filters and puts them in url
 */
export const useApplyFilters = () => {
  const removeFilter = useCallback(({ url = Router.asPath, filter }: { url?: string; filter: SelectedFilter }) => {
    const [pathname, query] = url.split('?');
    const newUrlParams = getQueryWithoutParams(query, getFilterParamsNames(filter));

    return pushWithNewParams(pathname, newUrlParams);
  }, []);

  const applyFilters = useCallback(
    ({ url = Router.asPath, filters = {} }: { url?: string; filters?: Partial<Filters> }) => {
      const appliedFilters = getUrlParamsFromFilters(filters);

      const [pathname, query] = url.split('?');
      const newUrlParams = getUrlParamsWithoutFilters(query);

      return pushWithFilters({ pathname, urlParams: newUrlParams, filters: appliedFilters });
    },
    [],
  );

  const clearFilters = useCallback(() => applyFilters({ filters: {} }), [applyFilters]);

  const clearSearchAndFilters = useCallback(() => {
    const [pathname, query] = Router.asPath.split('?');
    const queryWithoutFilters = getUrlParamsWithoutFilters(query).toString();
    const queryWithoutSearch = getQueryWithoutParams(queryWithoutFilters, [QueryParamNames.Search]).toString();

    const urlToGo = queryWithoutSearch ? `${pathname}?${queryWithoutSearch}` : pathname;

    return Router.push(urlToGo, undefined, { shallow: true });
  }, []);

  return {
    applyFilters,
    removeFilter,
    clearFilters,
    clearSearchAndFilters,
  };
};

import { ChevronRight } from '@air/next-icons';
import { ComboboxOptionText } from '@reach/combobox';

import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';
import { BoardSuggestionBreadcrumbs } from '~/components/SearchBar/Suggestion/BoardSuggestionBreadcrumbs';
import { SearchSuggestionContainer } from '~/components/SearchBar/Suggestion/SearchSuggestionContainer';
import { BoardSuggestionType } from '~/components/SearchBar/types';

interface BoardSuggestionProps {
  board: BoardSuggestionType;
  onClick: (board: BoardSuggestionType) => void;
  index: number;
}

export const BOARD_UNIQ_CHAR = '\u0000';
export const BOARD_UNIQ_CHAR_REGEXP = new RegExp(BOARD_UNIQ_CHAR, 'g');

export const BoardSuggestion = ({ board, onClick, index }: BoardSuggestionProps) => {
  // Combobox does not allow by default for duplicated options
  // If there are any options with the same value, both are highlighted when user navigates using arrow up/down
  // But we can have two boards with the same title. And there is no option from Combobox to recognize between board and search
  // For Combobox, option is always text (value)
  // So, this is a nasty hack to avoid such confusion. We add NULL (U+0000) char to each board and repeat it 'index' times
  // This way we do not display anything additional in search/suggestions, but for Combobox, values are different
  const uniqBoardTitle = `${board.title}${BOARD_UNIQ_CHAR.repeat(index)}`;

  return (
    <SearchSuggestionContainer
      index={index}
      value={uniqBoardTitle}
      onClick={() => onClick(board)}
      data-board-id={board.id}
    >
      <BoardThumbnail board={board} sizes="40px" width={40} height={40} />
      <div className="ml-3 flex flex-1 flex-col justify-center">
        <div className="truncate">
          <ComboboxOptionText />
        </div>
        {!!board.ancestors?.length ? <BoardSuggestionBreadcrumbs ancestors={board.ancestors ?? []} /> : null}
      </div>
      <ChevronRight className="h-6 w-6 text-grey-10" />
    </SearchSuggestionContainer>
  );
};

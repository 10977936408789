import Link from 'next/link';
import { memo, ReactNode } from 'react';

import { CatInBoxAsset } from '~/components/Assets/CatInBoxAsset';
import { NullState } from '~/components/NullState';

export interface NoSearchResultsProps {
  location: string | undefined;
  children: ReactNode;
}

export const NoSearchResults = memo(({ location, children }: NoSearchResultsProps) => (
  <NullState title={location ? `No results found in ${location}` : 'No results found'}>
    <div data-testid="NO_SEARCH_RESULTS" className="mt-2 flex flex-col items-center">
      {children}
      <CatInBoxAsset className="mb-8 mt-20" />
      <Link
        className="text-12"
        rel="noreferrer"
        href="https://help.air.inc/en/articles/3397283-searching-for-your-content"
        target="_blank"
      >
        Learn how search works in Air
      </Link>
    </div>
  </NullState>
));

NoSearchResults.displayName = 'NoSearchResults';

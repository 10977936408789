import { useBreakpointsContext } from '@air/provider-media-query';
import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from './useSearchParams';

export const useMobileSearchBar = () => {
  const { hasSearchParams } = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { isAboveMediumScreen } = useBreakpointsContext();

  useEffect(() => {
    if (hasSearchParams !== isOpen && !isAboveMediumScreen) {
      setIsOpen(hasSearchParams);
    }
    // we want to call this only if hasSearchParams is changed - e.g. when user goes directly to url with search param
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSearchParams]);

  const openMobileSearch = useCallback(() => setIsOpen(true), []);
  const closeMobileSearch = useCallback(() => setIsOpen(false), []);

  return {
    closeMobileSearch,
    openMobileSearch,
    isMobileSearchOpen: isOpen && !isAboveMediumScreen,
  };
};

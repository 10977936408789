import { CustomField } from '@air/api/types';
import { createSelector } from 'reselect';

import { getSelectedFilterValue } from '~/store/filters/utils';
import { AirState } from '~/store/types';

import { SelectedFilterValueObject, StringArrayFilterType } from './types';

export const baseFiltersSelector = ({ filters }: AirState) => filters;
export const customFieldIdSelector = (_: AirState, fieldId: CustomField['id']) => fieldId;

export const selectedFiltersSelector = createSelector(baseFiltersSelector, ({ selectedFilters }) => selectedFilters);

export const selectedItemTypesFiltersSelector = createSelector(baseFiltersSelector, ({ types }) => types);
export const hasItemTypesValuesSelector = createSelector(selectedItemTypesFiltersSelector, (types) => types.length > 0);

export const selectedColorsFiltersSelector = createSelector(baseFiltersSelector, ({ colors }) => colors);
export const hasColorsFiltersValuesSelector = createSelector(
  selectedColorsFiltersSelector,
  (colors) => colors.length > 0,
);

export const selectedTagsFilterLogicSelector = createSelector(baseFiltersSelector, ({ tags }) => tags.logic);
export const selectedTagsFiltersSelector = createSelector(baseFiltersSelector, ({ tags }) => tags.tags);
export const hasTagsFiltersValuesSelector = createSelector(selectedTagsFiltersSelector, (tags) => tags.length > 0);

export const selectedUploadersFilterSelector = createSelector(baseFiltersSelector, ({ uploaders }) => uploaders);
export const hasUploadersFilterValuesSelector = createSelector(
  selectedUploadersFilterSelector,
  (uploaders) => uploaders.length > 0,
);

export const selectedOtherFiltersSelector = createSelector(baseFiltersSelector, ({ others }) => others);
export const hasOtherFiltersValuesSelector = createSelector(
  selectedOtherFiltersSelector,
  (others) => others.length > 0,
);

export const customFieldFilterValuesSelector = createSelector(
  baseFiltersSelector,
  customFieldIdSelector,
  ({ customFields }, fieldId) => customFields.find(({ id }) => id === fieldId)?.values || [],
);

export const hasCustomFieldFilterValuesSelectedSelector = createSelector(
  customFieldFilterValuesSelector,
  (values) => values.length > 0,
);

export const customFieldFilterLogicSelector = createSelector(
  baseFiltersSelector,
  customFieldIdSelector,
  ({ customFields }, fieldId) => customFields.find(({ id }) => id === fieldId)?.logic ?? 'and',
);

export const createdDateFilterDateTypeSelector = createSelector(
  baseFiltersSelector,
  ({ createdDate }) => createdDate.type,
);
export const createdDateFilterStartDateSelector = createSelector(baseFiltersSelector, ({ createdDate }) =>
  createdDate.startDate ? new Date(createdDate.startDate) : undefined,
);
export const createdDateFilterEndDateSelector = createSelector(baseFiltersSelector, ({ createdDate }) =>
  createdDate.endDate ? new Date(createdDate.endDate) : undefined,
);

export const modifiedDateFilterDateTypeSelector = createSelector(
  baseFiltersSelector,
  ({ modifiedDate }) => modifiedDate.type,
);
export const modifiedDateFilterStartDateSelector = createSelector(baseFiltersSelector, ({ modifiedDate }) =>
  modifiedDate.startDate ? new Date(modifiedDate.startDate) : undefined,
);
export const modifiedDateFilterEndDateSelector = createSelector(baseFiltersSelector, ({ modifiedDate }) =>
  modifiedDate.endDate ? new Date(modifiedDate.endDate) : undefined,
);

export const uploadedDateFilterDateTypeSelector = createSelector(
  baseFiltersSelector,
  ({ uploadedDate }) => uploadedDate.type,
);
export const uploadedDateFilterStartDateSelector = createSelector(baseFiltersSelector, ({ uploadedDate }) =>
  uploadedDate.startDate ? new Date(uploadedDate.startDate) : undefined,
);
export const uploadedDateFilterEndDateSelector = createSelector(baseFiltersSelector, ({ uploadedDate }) =>
  uploadedDate.endDate ? new Date(uploadedDate.endDate) : undefined,
);

export const takenDateFilterDateTypeSelector = createSelector(baseFiltersSelector, ({ takenDate }) => takenDate.type);
export const takenDateFilterStartDateSelector = createSelector(baseFiltersSelector, ({ takenDate }) =>
  takenDate.startDate ? new Date(takenDate.startDate) : undefined,
);
export const takenDateFilterEndDateSelector = createSelector(baseFiltersSelector, ({ takenDate }) =>
  takenDate.endDate ? new Date(takenDate.endDate) : undefined,
);

type SelectedFilterValues = Partial<Record<SelectedFilterValueObject['key'], SelectedFilterValueObject['value']>>;

export const selectedFiltersValuesSelector = createSelector(baseFiltersSelector, (filters) => {
  const { selectedFilters } = filters;
  return selectedFilters.reduce<SelectedFilterValues>((acc, selectedFilter) => {
    const filter = getSelectedFilterValue(selectedFilter.type, filters);
    if (filter) {
      acc[filter.key] = filter.value;
    }
    return acc;
  }, {});
});

const stringArrayFilterTypeSelector = (_: AirState, filterType: StringArrayFilterType) => filterType;
export const selectedStringArrayFiltersSelector = createSelector(
  baseFiltersSelector,
  stringArrayFilterTypeSelector,
  (filters, filterType) => filters[filterType],
);
export const hasStringArrayFilterValuesSelector = createSelector(
  selectedStringArrayFiltersSelector,
  (values) => values.length > 0,
);

export const selectedImportedKeywordsFilterLogicSelector = createSelector(
  baseFiltersSelector,
  ({ importedKeywords }) => importedKeywords.logic,
);

export const selectedImportedKeywordsFilterValuesSelector = createSelector(
  baseFiltersSelector,
  ({ importedKeywords }) => importedKeywords.keywords,
);

export const selectedCameraMakeFiltersSelector = createSelector(baseFiltersSelector, ({ camera }) => camera.make);
export const selectedCameraModelsFiltersSelector = createSelector(baseFiltersSelector, ({ camera }) => camera.models);

export const selectedPeopleFilterLogicSelector = createSelector(baseFiltersSelector, ({ people }) => people.logic);
export const selectedPeopleFiltersSelector = createSelector(baseFiltersSelector, ({ people }) => people.people);
export const hasPeopleFiltersValuesSelector = createSelector(
  selectedTagsFiltersSelector,
  (people) => people.length > 0,
);

export const filtersValuesSelector = createSelector(
  baseFiltersSelector,
  ({
    createdDate,
    types,
    modifiedDate,
    uploadedDate,
    takenDate,
    tags,
    customFields,
    colors,
    uploaders,
    sources,
    others,
    extensions,
    countries,
    cities,
    states,
    copyrights,
    importedKeywords,
    creators,
    camera,
    videoFrameRates,
    videoAspectRatios,
    audioCodings,
    audioSampleRates,
    people,
  }) => ({
    states,
    camera,
    videoFrameRates,
    extensions,
    createdDate,
    types,
    modifiedDate,
    uploadedDate,
    takenDate,
    tags,
    customFields,
    colors,
    uploaders,
    sources,
    others,
    countries,
    cities,
    importedKeywords,
    copyrights,
    creators,
    videoAspectRatios,
    audioCodings,
    audioSampleRates,
    people,
  }),
);
